import styled, { css } from 'styled-components';

const commonStyles = css`
  width: 100%;
  font-size: 10px;
  line-height: 1.5;
  box-sizing: border-box;
  font-family: 'Heebo', sans-serif;
  color: #17182f;
  -webkit-font-smoothing: antialiased;
  gap: 16px;

  img {
    max-width: 100%;
  }
  a {
    text-decoration: none;
  }
  * {
    box-sizing: border-box;
  }
`;

export const Container = styled.div`
  ${commonStyles}
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
`;

export const ContainerEvent = styled.div`
  ${commonStyles}
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  margin: 0 auto;
`;

export const FuturesTableContainer = styled.div`
  ${commonStyles}
`;

export const GlobleContainer = styled.div`
  grid-template-columns: 1fr;
  grid-row-gap: 0.4rem;

  ${(props) =>
    !props.isSportsWidget &&
    css`
      @media (min-width: 1100px) {
        grid-column-gap: 0.4rem;
        grid-row-gap: 0;
        grid-template-columns: repeat(2, 1fr);
      }
    `}
`;
