/* eslint-disable array-callback-return */
import axios from 'axios';
import { sportNameToScope, groupBy } from 'sports-util';
import { access } from '../providers/AuthProvider';

/* -------------------------------------------------------------------------- */
/*                             GET REFRESH TOKEN                              */
/* -------------------------------------------------------------------------- */
export const getAccessToken = async () => {
  const formData = new FormData();
  const data = {
    grant_type: process.env.REACT_APP_AUTH_GRANT_TYPE,
    client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
    refresh_token: process.env.REACT_APP_AUTH_REFRESH_TOKEN,
  };
  Object.keys(data).forEach((key) => formData.append(key, data[key]));
  axios.defaults.baseURL = process.env.REACT_APP_AUTH_API_URL;
  const response = await axios.post('/token', formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  return response.data;
};

/* -------------------------------------------------------------------------- */
/*                             GET ODDS DATA                                  */
/* -------------------------------------------------------------------------- */
export const getOddsData = async (eventID, sportName, oddType, providers, lang, ouValue) => {
  axios.defaults.baseURL = process.env.REACT_APP_EVENTS_API_URL;
  const response = await axios.get(
    `/odds/events/${eventID}/${oddType === 'ht_ft' ? '1x2' : oddType}${
      oddType === 'ou' ? `/${ouValue}` : ``
    }?providers=${providers}${`&scope=${
      oddType === 'ht_ft' ? '1h' : sportNameToScope(sportName)
    }&lang=${lang}`}`,
    {
      headers: {
        'x-api-key': access.token,
      },
    },
  );

  if (oddType === 'gs' && response.data.odds.length > 0) {
    const playerList = groupBy(response.data.odds[0].offers, 'participant.name');

    const players = [];
    for (const [key] of Object.entries(playerList)) {
      players[key] = [];
      response.data.odds.map((odds, index) => {
        const offers = odds.offers.filter((offer) => {
          return offer.participant.name === key && offer.type === 'anytime';
        });

        if (offers.length > 0) {
          players[key].push({
            provider: odds.odds_provider.short_code,
            offers: offers,
          });
        }
      });
    }

    return {
      event: response.data.event,
      odds: Object.entries(players).sort(function (a, b) {
        return a[1][0].offers[0].odds - b[1][0].offers[0].odds;
      }),
    };
  } else {
    return response.data;
  }
};

export const hasOdds = async (eventIds, oddType, providers) => {
  axios.defaults.baseURL = process.env.REACT_APP_EVENTS_API_URL;
  const response = await axios.get(
    `/sports-api/events/has-odds?events=${eventIds}&types=${oddType}&providers=${providers}`,
    {
      headers: {
        'x-api-key': access.token,
      },
    },
  );
  return response.data;
};

/* -------------------------------------------------------------------------- */
/*                             GET WIDGET DATA                                */
/* -------------------------------------------------------------------------- */
export const getWidgetData = async (widgetToken, lang) => {
  if (widgetToken) {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    const response = await axios.get(`/${widgetToken}${lang ? `?lang=${lang}` : ''}`);
    const data = response.data.result;

    if (response.data.result.widget_type === 'Event') {
      await hasOdds(
        data.event_ids,
        data.odds_types,
        data.providers.map((provider) => provider.short_name),
      );
    }
    return response.data;
  }
  return null;
};

/* -------------------------------------------------------------------------- */
/*                             GET WINNER DATA                                */
/* -------------------------------------------------------------------------- */
export const getWinnerOfStage = async (eventId, lang) => {
  const result = { odds: [], event: {} };
  if (eventId) {
    axios.defaults.baseURL = process.env.REACT_APP_EVENTS_API_URL;
    const response = await axios.get(
      `sports-api/tournament-stages/${eventId}/odds/winner-of-stage${lang ? `?lang=${lang}` : ''}`,
      {
        headers: {
          'x-api-key': access.token,
        },
      },
    );
    const eventData = await getTournamentData(eventId);
    if (response.data.length) {
      result.odds = response.data;
    } else {
      result.odds = await getWinnerOfTournament(eventData.tournament.id);
    }
    result.event = eventData;
    return result;
  }
  return null;
};

export const getTournamentData = async (eventId) => {
  axios.defaults.baseURL = process.env.REACT_APP_EVENTS_API_URL;
  const response = await axios.get(`sports-api/tournament-stages/${eventId}`, {
    headers: {
      'x-api-key': access.token,
    },
  });

  return response.data;
};

export const getWinnerOfTournament = async (eventId) => {
  axios.defaults.baseURL = process.env.REACT_APP_EVENTS_API_URL;
  const response = await axios.get(`sports-api/tournaments/${eventId}/odds/winner-of-tournament`, {
    headers: {
      'x-api-key': access.token,
    },
  });

  return response.data;
};
