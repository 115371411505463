import styled from 'styled-components';

export const StarItem = styled.span`
  color: #165af8;
  &:before {
    content: '★';
    right: 16px;
    top: 15px;
    font-size: 12px;
    position: absolute;
    top: 28%;
  }
`;
