import PropTypes from 'prop-types';
import { useRef, useEffect, useState } from 'react';
import { getOperatorName } from 'helpers';
import {
  checkStatusIsLive,
  getHighestOdds,
  getProvidersQuery,
  generateOddsTracker,
  getTranslations,
  padNumber,
} from 'sports-util';
import { oddsTranslations } from 'sports-util/dist/constants';
import useOddsData from 'hooks/useOddsData';
import OddsEventLoading from 'components/atoms/odds-event-loading';
import Arrow from 'components/atoms/arrow';

import {
  Wrapper,
  OddsWrapper,
  OddsValue,
  OfferValue,
  OfferType,
  Title,
  TermsAndConditions,
  LiveBet,
} from './styles';
import propTypes from 'prop-types';

export default function OddsEvents({
  eventId = 3560740,
  sportName = 'soccer',
  oddType = '1x2',
  mockData = { odds: [] },
  providers = [],
  oddsFormat = 'decimal',
  prettyLink,
  prettyLinkFormat,
  tracker,
  lang,
  ouValue = 2.5,
  isHeader = true,
}) {
  const timestampRef = useRef(null);
  const liveBetProvider = providers[0];
  const [isLive, setIsLive] = useState(false);

  const { data, error, isLoading, isError } =
    mockData.odds.length > 0
      ? {
          data: mockData,
          error: { message: '' },
          isLoading: false,
          isError: false,
        }
      : // eslint-disable-next-line react-hooks/rules-of-hooks
        useOddsData(eventId, sportName, oddType, getProvidersQuery(providers), lang, ouValue);

  useEffect(() => {
    const today = new Date();

    if (!isError && !isLoading && data?.odds?.length) {
      timestampRef.current &&
        (timestampRef.current.innerText = `${getTranslations(
          lang,
          'last_update',
          oddsTranslations,
        )} ${getTranslations < 'odd' > (lang, 'today')}, ${
          getTranslations < 'odd' > (lang, 'at')
        } ${padNumber(today.getHours())}:${padNumber(today.getMinutes())}`);
    }

    setIsLive(checkStatusIsLive(data?.event.status));
  }, [data, lang, isError, isLoading]);

  if (isLoading)
    return (
      <Wrapper>
        <OddsEventLoading />
      </Wrapper>
    );
  if (isError)
    return (
      <Wrapper>
        <OddsEventLoading isError />
        {console.log(error.response?.data?.errors?.join(', ') || error.message)}
      </Wrapper>
    );

  const highestOdds = getHighestOdds(data.odds, oddType);

  if ((!data?.odds?.length || highestOdds.length === 0) && !isLive) return null;

  const onOddsClick = (e, provider, coupon_key) => {
    e.preventDefault();
    window.open(generateOddsTracker(provider, tracker, coupon_key), '_blank');
  };

  const checkArrow = (offer) => {
    return Number(offer.odds) > Number(offer.odds_old);
  };

  return (
    <Wrapper>
      {isHeader && <Title>{getTranslations < 'odd' > (lang, 'who_will_win')}</Title>}
      <OddsWrapper>
        {isLive ? (
          <LiveBet onClick={(e) => onOddsClick(e, liveBetProvider)}>
            <img
              src={`${process.env.REACT_APP_CDN_URL}/${liveBetProvider?.logo_url}`}
              alt={getOperatorName(liveBetProvider)}
              width={40}
              height={40}
            />
            <OfferValue>{`Bet Live on ${getOperatorName(liveBetProvider)}`}</OfferValue>
          </LiveBet>
        ) : (
          highestOdds.map((item, index) => {
            const provider = providers.find(function (element) {
              return element.short_name === item.odds_provider.short_code;
            });

            return (
              <OddsValue
                key={index}
                onClick={(e) => onOddsClick(e, provider, item.offers.coupon_key)}
                isHeader={isHeader}
              >
                <img
                  src={`${process.env.REACT_APP_CDN_URL}/${provider?.logo_url}`}
                  alt={getOperatorName(provider)}
                  width={40}
                  height={40}
                />
                <OfferType>{item.offers.offer_type}</OfferType>
                <OfferValue>{item.offers.odds}</OfferValue>
                <span className={checkArrow(item.offers) ? 'up' : 'down'}>
                  <Arrow
                    color={checkArrow(item.offers) ? 'green' : 'red'}
                    direction={checkArrow(item.offers) ? 'up' : 'down'}
                  />
                </span>
              </OddsValue>
            );
          })
        )}
      </OddsWrapper>
      {isHeader && (
        <TermsAndConditions ref={timestampRef}>Odds Updated: Today, at 00:00</TermsAndConditions>
      )}
    </Wrapper>
  );
}

OddsEvents.propTypes = {
  eventId: PropTypes.number,
  sportName: PropTypes.string,
  oddType: PropTypes.string,
  oddsFormat: PropTypes.oneOf(['american', 'decimal']),
  mockData: PropTypes.shape({}),
  providers: PropTypes.array,
  lang: PropTypes.string,
  prettyLink: PropTypes.string,
  prettyLinkFormat: PropTypes.string,
  tracker: PropTypes.string,
  isHeader: propTypes.bool,
};
