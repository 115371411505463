import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  min-height: 280px;
  width: ${(props) => props.width || '100%'};
`;
export const Err = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 16px;
  left: 0;
  top: 50%;
  font-weight: 500;
`;
export const LoadingBox = styled.div`
  position: absolute;
  left: ${(props) => props.left}px;
  right: ${(props) => props.right}px;
  top: ${(props) => props.top}px;
  width: ${(props) => props.width};
  height: ${(props) => props.height}px;
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  animation: oodsLoading 1.4s ease infinite;
  background-size: 400% 100%;
`;
