import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import { shouldRetry } from '../services/error';
import { getWidgetData } from '../services';

export default function useWidgetData(token, lang = 'en', maxRetries = 10, retryDelay = 2000) {
  return useQuery(['token', token], () => getWidgetData(token, lang), {
    refetchOnWindowFocus: false,
    refetchInterval: false,
    retryDelay,
    retry: (retries, error) => retries < maxRetries && shouldRetry(error),
  });
}

useWidgetData.propTypes = {
  token: PropTypes.string,
  lang: PropTypes.string,
  maxRetries: PropTypes.number,
  retryDelay: PropTypes.number,
};
