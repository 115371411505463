import React from 'react';
import ReactDOM from 'react-dom';

import { OddsWidget } from './odds-widget';

const mountedElement = document.getElementById('root');

ReactDOM.render(
  <React.StrictMode>
    <OddsWidget
      dataToken={mountedElement.getAttribute('data-token')}
      dataOddsFormat={mountedElement.getAttribute('data-odds-format')}
      dataLang={mountedElement.getAttribute('data-lang')}
      dataPrettyLink={mountedElement.getAttribute('data-pretty-link')}
      dataPrettyLinkFormat={mountedElement.getAttribute('data-pretty-link-format')}
      dataEventId={0}
      dataSportName=""
      dataProviders={[]}
      dataTrackers=""
      dataOddType=""
      dataSportsWidget={false}
      isHeader={true}
    />
  </React.StrictMode>,
  mountedElement
);
