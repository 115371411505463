import PropTypes from 'prop-types';

import { generateOddsTracker } from 'sports-util';
import OddCell from 'components/atoms/odd-cell';
import { getOperatorName } from 'helpers';

import { Row, ProviderName, ProviderLogo, OddsRow } from './styles';

export default function ProviderRow({
  provider,
  oddsData = [],
  oddsFormat = 'decimal',
  isDisabled = false,
  prettyLinkUrl = null,
  tracker = null,
  oddType = '',
  height,
  width,
}) {
  return (
    <Row>
      <ProviderLogo
        href={prettyLinkUrl}
        rel="noopener noreferrer nofollow"
        target="_blank"
        oddType={oddType}
      >
        <img
          src={`${process.env.REACT_APP_CDN_URL}/${
            provider?.standardised_logo_url || provider?.logo_url
          }`}
          alt={getOperatorName(provider)}
          width={width || 40}
          height={height || 40}
        />
      </ProviderLogo>
      {oddType !== 'Winner table' && <ProviderName>{getOperatorName(provider)}</ProviderName>}
      {!!oddsData.length && (
        <OddsRow>
          {oddsData.map((item, index) => (
            <OddCell
              key={index}
              link={generateOddsTracker(provider, tracker, item.coupon_key)}
              prettyLinkUrl={prettyLinkUrl}
              odds={item.odds}
              odds_old={item.odds_old}
              isDisabled={isDisabled || item.status.id !== 1}
              isHighlighted={item.isHighlighted}
              oddsFormat={oddsFormat}
            />
          ))}
        </OddsRow>
      )}
    </Row>
  );
}

ProviderRow.propTypes = {
  provider: PropTypes.shape({}),
  oddsData: PropTypes.arrayOf(PropTypes.shape({})),
  oddsFormat: PropTypes.oneOf(['american', 'decimal']),
  isDisabled: PropTypes.bool,
  oddType: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
