import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: transparent;
  padding: 16px;
  text-align: center;

  @media (max-width: 767px) {
    padding: 16px 0;
  }
`;

export const OddsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  @media (min-width: 375px) {
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const OddsValue = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 5px;
  background: #ffffff;
  padding-right: 10px;
  border-radius: 8px;
  width: 118px;
  outline: 1.5px solid #17182f;
  box-sizing: border-box;
  height: 40px;

  ${(props) =>
    props.isHeader &&
    css`
      background: #f6f6fb;
    `}

  img {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
`;

export const OfferType = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  padding-left: 5px;
  color: #6e6e84;
`;

export const OfferValue = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  flex-grow: 1;
  text-align: center;
  color: #17182f;
`;

export const Title = styled.p`
  font-size: 14px;
  color: #17182f;
  padding: 8px;
  margin: 0;
`;

export const TermsAndConditions = styled.div`
  font-size: 10px;
  color: #6e6e84;
  padding: 8px;
  margin: 0;
`;

export const LiveBet = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 5px;
  background: #165af8;
  padding-right: 10px;
  border-radius: 8px;
  width: 246px;
  border: 1.5px solid #17182f;
  box-sizing: border-box;
  border-radius: 8px;

  img {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  div {
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
`;
