import PropTypes from "prop-types";

import Arrow from "components/atoms/arrow";

import { Row, OddTitle, OddsRow } from "./styles";

export default function ToplistHeader({
  oddType = "1x2",
  sortBy = "",
  splitBy = [
    { title: "1", type: "1" },
    { title: "x", type: "x" },
    { title: "2", type: "2" },
  ],
  handleSortBy,
}) {
  return (
    <Row className="toplist-header">
      <OddsRow>
        {splitBy.map((item, index) => (
          <OddTitle
            key={index}
            id={`id-${item.type}`}
            onClick={() => handleSortBy(item.type, oddType)}
            isSorted={sortBy === item.type ? true : false}
          >
            {item.title}
            {sortBy === item.type ? (
              <span className="down">
                <Arrow color="black" direction="down" />
              </span>
            ) : null}
          </OddTitle>
        ))}
      </OddsRow>
    </Row>
  );
}

ToplistHeader.propTypes = {
  oddType: PropTypes.string,
};
