import PropTypes from 'prop-types';
import { useState } from 'react';
import defaultLogo from 'images/default_team_logo.svg'; // with import

export default function TeamLogo({ enet_pulse_id = 0, team_name = '', width = 20, height = 20 }) {
  const [src, setSrc] = useState(`${process.env.REACT_APP_TEAM_LOGO}/${enet_pulse_id}.png`);

  return (
    <img
      src={src}
      alt={team_name}
      width={width}
      height={height}
      onError={() => {
        setSrc(defaultLogo);
      }}
    />
  );
}

TeamLogo.propTypes = {
  enet_pulse_id: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  team_name: PropTypes.string,
};
