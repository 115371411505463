import PropTypes from 'prop-types';
import { LoadingBox, Wrapper, Err } from './styles';

export default function OddsLoading({ isError = false }) {
  return (
    <Wrapper width={'544px'}>
      <LoadingBox width={'calc(100% - 136px)'} height={18} top={0} left={16} />
      <LoadingBox width={'88px'} height={44} top={0} right={16} />
      <LoadingBox width={'calc(100% - 136px)'} height={18} top={24} left={16} />

      <LoadingBox width={'44px'} height={44} top={80} left={16} />
      <LoadingBox width={'125px'} height={44} top={80} left={64} />
      <LoadingBox width={'calc(100% - 209px)'} height={44} top={80} right={16} />
      <LoadingBox width={'44px'} height={44} top={128} left={16} />
      <LoadingBox width={'125px'} height={44} top={128} left={64} />
      <LoadingBox width={'calc(100% - 209px)'} height={44} top={128} right={16} />
      <LoadingBox width={'44px'} height={44} top={176} left={16} />
      <LoadingBox width={'125px'} height={44} top={176} left={64} />
      <LoadingBox width={'calc(100% - 209px)'} height={44} top={176} right={16} />
      <LoadingBox width={'100%'} height={50} top={230} />
      {isError && <Err>Error loading odds feed</Err>}
    </Wrapper>
  );
}

OddsLoading.propTypes = {
  isError: PropTypes.bool,
};
