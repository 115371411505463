import { useState } from 'react';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import { shouldRetry } from '../services/error';
import { getOddsData } from '../services';

export default function useOddsData(
  eventId,
  sportName,
  oddType,
  providers,
  lang,
  ouValue,
  onWindowFocus = false,
  refetchInterval = 60000,
  maxRetries = 20,
  retryDelay = 5000,
) {
  const [stop, setStop] = useState(false);
  return useQuery(
    ['event', eventId],
    () => getOddsData(eventId, sportName, oddType, providers, lang, ouValue),
    {
      onSuccess: (data) => data?.odds?.length && setStop(true),
      refetchOnWindowFocus: onWindowFocus,
      refetchInterval: stop ? false : refetchInterval,
      retryDelay,
      retry: (retries, error) => retries < maxRetries && shouldRetry(error),
    },
  );
}

useOddsData.propTypes = {
  eventId: PropTypes.number,
  sportName: PropTypes.string,
  oddType: PropTypes.string,
  providers: PropTypes.string,
  lang: PropTypes.string,
  ouValue: PropTypes.string,
  onWindowFocus: PropTypes.boolean,
  refetchInterval: PropTypes.number,
  maxRetries: PropTypes.number,
  retryDelay: PropTypes.number,
};
