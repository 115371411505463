import styled, { css } from 'styled-components';

export const Row = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 8px;
  flex-shrink: 0;
`;

export const ProviderLogo = styled.a`
  display: inline-block;
  width: 40px;
  height: 40px;
  flex-basis: 40px;
  background: #f6f6fb;

  ${(props) =>
    props.oddType === 'Winner table' &&
    css`
      display: flex;
      position: relative;
      z-index: 1;
      justify-content: center;
      background: transparent;
    `}
`;

export const ProviderName = styled.span`
  display: inline-block;
  margin: 0 4px;
  height: 24px;
  width: 125px;
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const OddsRow = styled.div`
  display: inline-flex;
  flex: 1;
  > a {
    margin-left: 4px;
    flex: 1;
  }
`;
