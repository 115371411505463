import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
// import { ReactQueryDevtools } from "react-query/devtools";

// import { AuthProvider } from './providers/AuthProvider';

import App from './App';

const queryClient = new QueryClient();

export const OddsWidget = ({
  dataToken,
  dataOddsFormat,
  dataLang,
  dataPrettyLink,
  dataPrettyLinkFormat,
  dataEventId,
  dataSportName,
  dataProviders,
  dataTrackers,
  dataOddType,
  dataSportsWidget = false,
  isHeader = false,
}) => (
  <QueryClientProvider client={queryClient} contextSharing={true}>
    {/* <AuthProvider> */}
    <App
      dataToken={dataToken}
      dataOddsFormat={dataOddsFormat}
      dataLang={dataLang}
      dataPrettyLink={dataPrettyLink}
      dataPrettyLinkFormat={dataPrettyLinkFormat}
      dataEventId={dataEventId}
      dataSportName={dataSportName}
      dataProviders={dataProviders}
      dataTrackers={dataTrackers}
      dataOddType={dataOddType}
      dataSportsWidget={dataSportsWidget}
      isHeader={isHeader}
    />
    {/* </AuthProvider> */}
    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
  </QueryClientProvider>
);
