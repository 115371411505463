import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 8px 0;
  padding: 0 16px;
`;

export const TextInfo = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #6e6e84;
  line-height: 21px;
`;

export const Button = styled.a`
  display: inline-block;
  color: #17182f;
  background-color: #0cd722;
  font-size: 14px;
  font-weight: 500;
  padding: 9px 16px;
  white-space: nowrap;
`;
