import styled from 'styled-components';

export const ToplistWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 8px 0 8px 0;
  max-height: 200px;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
  padding: 0 16px;
  &.open {
    max-height: 2000px;
    transition: max-height 0.5s ease-in;
  }
`;

export const Timestamp = styled.div`
  width: 100%;
  font-size: 12px;
  line-height: 18px;
  color: #6e6e84;
  margin: 10px 0 13px;
  text-align: center;
  @media (min-width: 768px) {
    ${(props) => (props.hasStarted ? 'margin: 8px 0 8px;' : 'margin: 8px 0 16px;')}
  }
`;

export const Handicap = styled.div`
  padding: 4px;
  width: fit-content;
  font-size: 14px;
  line-height: 24px;
  color: #17182f;
  background-color: #ebf1ff;
  border-radius: 4px;
  text-align: center;
`;

export const SeeMore = styled.button`
  width: 100%;
  background: #f6f6fb;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  &:focus {
    border: none;
    outline: none;
  }
  span {
    margin-right: 7px;
    font-size: 16px;
    line-height: 24px;
    color: #165af8;
    font-weight: 500;
  }
  &.open {
    span:last-child {
      transform: rotate(0deg);
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  background: white;
  min-height: 250px;
  border: 1.5px solid #e3e6ef;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
