import styled, { css } from 'styled-components';

export const Row = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 8px;
  flex-shrink: 0;
`;

export const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  background: #ffffff;
  border: 1.5px solid #17182f;
  width: 180px;
  min-width: 180px;
  height: 40px;
  left: 0;
  z-index: 1;
  border-radius: 8px;

  &:hover,
  &:focus {
    background: #f3f5f8;
    border: 1px solid #dadee1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  ${(props) =>
    props.isDisabled &&
    css`
      border-color: #dadee1;
      cursor: not-allowed;
      pointer-events: none;

      span {
        color: #a4adb7;
      }

      img {
        opacity: 0.5;
      }
    `}

  @media (min-width: 768px) {
    width: 240px;
    min-width: 240px;
  }
`;

export const OddsRow = styled.div`
  display: inline-flex;
  flex: 1;

  a:nth-child(odd) {
    ::after {
      content: '';
      display: block;
      position: absolute;
      background: #f1f2f9;
      width: calc(100% + 8px);
      height: calc(100% + 11px);
      left: -4px;
      z-index: -1;
    }
  }
`;

export const TeamName = styled.span`
  margin: 0;
  margin-left: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #021d38;
`;
