import styled from 'styled-components';

export const FuturesTableWrapper = styled.div`
  width: calc(100vw - 48px);
  display: flex;
  flex-direction: column;
  margin: 24px 0 8px 0;
  overflow-x: auto;
  transition: width 0.5s ease-out;

  @media (min-width: 768px) {
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  background: white;
  padding: 16px;
  min-height: 250px;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
`;

export const FuturesHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  flex-shrink: 0;
`;

export const ProvidersRow = styled.div`
  display: flex;

  > div {
    position: relative;
    width: auto;
    margin: 0;

    &:nth-of-type(odd) {
      a::after {
        content: '';
        display: block;
        position: absolute;
        background: #f1f2f9;
        width: calc(100% + 8px);
        height: 100%;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        top: 50%;
        left: -4px;
        z-index: -1;
      }
    }
  }

  a {
    width: 80px;
    flex-basis: 80px;
    margin-left: 8px;
  }
`;

export const EmptyCell = styled.div`
  background: #ffffff;
  padding: 9px 8px;
  width: 180px;
  min-width: 180px;
  left: 0;
  z-index: 1;

  @media (min-width: 768px) {
    width: 240px;
    min-width: 240px;
  }
`;

export const FuturesOdds = styled.div`
  > div:last-child {
    a:nth-child(odd)::after {
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }
`;
