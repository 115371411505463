import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getOperatorName } from 'helpers';
import {
  getProvidersQuery,
  providersArrayToObject,
  getTranslations,
  padNumber,
  generateTracker,
} from 'sports-util';
import { oddsTranslations } from 'sports-util/dist/constants';
import Header from 'components/molecules/header';
import Footer from 'components/molecules/footer';
import OddsLoading from 'components/atoms/odds-loading';
import Arrow from 'components/atoms/arrow';
import useOddsData from 'hooks/useOddsData';

import {
  ToplistWrapper,
  Timestamp,
  PlayerOdd,
  AllOddsProviderInfo,
  Wrapper,
  PlayerWrapper,
  AllOdds,
  AllOddsProvider,
  Tandc,
  LoadMoreButton,
} from './styles';

export default function OddsToplist({
  eventId = 3560740,
  sportName = 'soccer',
  oddType = '1x2',
  mockData = { odds: [] },
  providers = [],
  oddsFormat = 'decimal',
  prettyLink,
  prettyLinkFormat,
  tracker,
  lang,
  ouValue = 2.5,
}) {
  const boxRef = useRef(null);
  const loadMoreRef = useRef(null);
  const timestampRef = useRef(null);
  const searchParams = new URLSearchParams(window.location.search);
  const site = searchParams.get('site');
  const allOddsRef = useRef([]);
  const allPlayersRef = useRef([]);
  const [loadMoreEnabled, setLoadMoreEnabled] = useState('');

  const { data, error, isLoading, isError } =
    mockData.odds.length > 0
      ? {
          data: mockData,
          error: { message: '' },
          isLoading: false,
          isError: false,
        }
      : // eslint-disable-next-line react-hooks/rules-of-hooks
        useOddsData(eventId, sportName, oddType, getProvidersQuery(providers), lang, ouValue);

  useEffect(() => {
    const today = new Date();
    !isLoading &&
      !isError &&
      data?.odds?.length &&
      (timestampRef.current.innerText = `${getTranslations(
        lang,
        'last_update',
        oddsTranslations,
      )} ${getTranslations(lang, 'today', oddsTranslations)}, ${getTranslations(
        lang,
        'at',
        oddsTranslations,
      )} ${padNumber(today.getHours())}:${padNumber(today.getMinutes())}`);
  }, [data, lang, isLoading, isError]);

  if (isLoading)
    return (
      <Wrapper>
        <OddsLoading />
      </Wrapper>
    );
  if (isError)
    return (
      <Wrapper>
        <OddsLoading isError />
        {console.log(error.response?.data?.errors?.join(', ') || error.message)}
      </Wrapper>
    );

  const hasStarted = data?.event?.status !== 'Not started';
  const providersObject = providersArrayToObject(providers);

  const handleClick = (index) => {
    allOddsRef.current[index] && allOddsRef.current[index].classList.toggle('open');
  };

  const prettyLinkUrl = (provider) => {
    return (
      site &&
      `https://${site}.gigmedia.com${generateTracker(
        prettyLinkFormat,
        provider,
        tracker,
        prettyLink,
        lang,
      )}`
    );
  };

  const onOddsClick = (e, provider) => {
    e.preventDefault();
    window.open(prettyLinkUrl(provider), '_blank');
  };

  const loadMoreClick = (e) => {
    e.preventDefault();
    for (let i = 6; i < allPlayersRef.current.length; i++) {
      allPlayersRef.current[i].classList.toggle('show');
    }
    allPlayersRef.current.length > 5 &&
      setLoadMoreEnabled(allPlayersRef.current[6].classList.contains('show'));
  };

  return (
    <Wrapper>
      <Header
        oddType={oddType}
        title={`${data?.event?.event_participants[0]?.participant?.name} vs ${data?.event?.event_participants[1]?.participant?.name}`}
        date={data?.event?.start}
        lang={lang}
      />
      <ToplistWrapper showShadow={loadMoreEnabled} ref={boxRef} className="toplist-wrapper">
        {data.odds.map((item, index) => {
          const provider = providersObject[item[1][0].provider];
          const offer = item[1][0].offers.filter((item) => item.type === 'anytime');
          return (
            <>
              <PlayerWrapper
                hide={index > 4}
                ref={(el) => (allPlayersRef.current[index] = el)}
                onClick={() => handleClick(index)}
              >
                <span>
                  {item[1].length > 1 ? (
                    <>
                      <Arrow color="#17182F" direction="down" />
                    </>
                  ) : (
                    <span>&nbsp;&nbsp;</span>
                  )}
                  {item[0]}
                </span>
                <PlayerOdd onClick={(e) => onOddsClick(e, provider)}>
                  <img
                    src={`${process.env.REACT_APP_CDN_URL}/${
                      provider?.standardised_logo_url || provider?.logo_url
                    }`}
                    alt={getOperatorName(provider)}
                    width={32}
                    height={32}
                  />
                  {offer[0].odds}
                  {offer[0].odds > offer[0].odds_old && (
                    <span>
                      <Arrow color="green" direction="up" />
                    </span>
                  )}
                  {offer[0].odds < offer[0].odds_old && (
                    <span>
                      <Arrow color="red" direction="down" />
                    </span>
                  )}
                  {offer[0].odds === offer[0].odds_old && <span>&nbsp;</span>}
                </PlayerOdd>
              </PlayerWrapper>
              {item[1].length > 1 && (
                <AllOdds ref={(el) => (allOddsRef.current[index] = el)}>
                  <div>
                    <span>Welcome Offers</span>
                    <span>All Odds</span>
                  </div>
                  {item[1].map((item, index) => {
                    const gsProvider = providersObject[item.provider];
                    const gsOffer = item.offers.filter((item) => item.type === 'anytime');

                    return (
                      <AllOddsProvider>
                        <AllOddsProviderInfo>
                          <span>{getOperatorName(gsProvider)}</span>
                          <span>{gsProvider.one_liners.main.one_liner}</span>
                        </AllOddsProviderInfo>

                        <PlayerOdd
                          onClick={(e) => onOddsClick(e, gsProvider)}
                          isHighlighted={index === 0}
                        >
                          <img
                            src={`${process.env.REACT_APP_CDN_URL}/${
                              gsProvider?.standardised_logo_url || gsProvider?.logo_url
                            }`}
                            alt={getOperatorName(gsProvider)}
                            width={32}
                            height={32}
                          />
                          {gsOffer[0].odds}
                          {gsOffer[0].odds > gsOffer[0].odds_old && (
                            <span>
                              <Arrow color="green" direction="up" />
                            </span>
                          )}
                          {gsOffer[0].odds < gsOffer[0].odds_old && (
                            <span>
                              <Arrow color="red" direction="down" />
                            </span>
                          )}
                          {gsOffer[0].odds === gsOffer[0].odds_old && <span>&nbsp;</span>}
                        </PlayerOdd>
                        {gsProvider.extra_fields?.terms_and_conditions_text_enabled && (
                          <Tandc>{gsProvider.extra_fields?.terms_and_conditions}</Tandc>
                        )}
                      </AllOddsProvider>
                    );
                  })}
                </AllOdds>
              )}
            </>
          );
        })}
      </ToplistWrapper>
      <LoadMoreButton ref={loadMoreRef} onClick={(e) => loadMoreClick(e)}>
        {loadMoreEnabled ? ' Show Less' : 'Show More'}
      </LoadMoreButton>
      <Timestamp hasStarted={hasStarted} ref={timestampRef}>
        Odds Updated: Today, at 00:00
      </Timestamp>
      {hasStarted && <Footer lang={lang} />}
    </Wrapper>
  );
}

OddsToplist.propTypes = {
  eventId: PropTypes.number,
  sportName: PropTypes.string,
  oddType: PropTypes.string,
  oddsFormat: PropTypes.oneOf(['american', 'decimal']),
  mockData: PropTypes.shape({}),
  providers: PropTypes.array,
  lang: PropTypes.string,
  prettyLink: PropTypes.string,
  prettyLinkFormat: PropTypes.string,
  tracker: PropTypes.string,
};
