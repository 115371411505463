import PropTypes from 'prop-types';
import {
  LoadingBox,
  Wrapper,
  Err,
  Table,
  FuturesRow,
  FuturesLoadingBox,
  FuturesLoadingCell,
  EmptyFuturesCell,
} from './styles';

export default function FuturesLoading({ isError = false }) {
  return (
    <Wrapper width={'960px'}>
      <LoadingBox width={'calc(100% - 136px)'} height={36} top={0} left={0} />
      <LoadingBox width={'100px'} height={36} top={0} right={0} />

      <Table>
        <FuturesRow>
          <EmptyFuturesCell width={'240px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
        </FuturesRow>
        <FuturesRow>
          <FuturesLoadingBox width={'240px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
        </FuturesRow>
        <FuturesRow>
          <FuturesLoadingBox width={'240px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
        </FuturesRow>
        <FuturesRow>
          <FuturesLoadingBox width={'240px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
        </FuturesRow>
        <FuturesRow>
          <FuturesLoadingBox width={'240px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
          <FuturesLoadingCell width={'80px'} height={40} />
        </FuturesRow>
      </Table>
      {isError && <Err>Error loading odds feed</Err>}
    </Wrapper>
  );
}

FuturesLoading.propTypes = {
  isError: PropTypes.bool,
};
