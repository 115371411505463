import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    @keyframes oddsFadeIn {
        from {opacity:0;}
        to {opacity:1;}
    }
    @keyframes oddsRotating {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
    .odds-fade-in{
        animation: oddsFadeIn;
        animation-delay: 200ms;
        animation-fill-mode: backwards;
        animation-duration: 600ms;
        opacity: 1;
    }
    @keyframes oodsLoading{0%{background-position:100% 50%}100%{background-position:0 50%}}
    
    #root{
        grid-template-columns: 1fr;
        grid-row-gap:.4rem;

        @media (min-width: 1100px) {
          grid-column-gap:.4rem;
          grid-row-gap: 0;
          grid-template-columns: repeat(2, 1fr);
        }
    }
`;
export default GlobalStyle;
