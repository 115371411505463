import PropTypes from 'prop-types';

import { generateTracker, generateOddsTracker } from 'sports-util';
import OddCell from 'components/atoms/odd-cell';
import TeamLogo from 'components/atoms/team/logo';

import { Row, TableHeader, OddsRow, TeamName } from './styles';
export default function FuturesRow({
  isDisabled = false,
  tracker = null,
  team = '',
  offers = [],
  prettyLink,
  prettyLinkFormat,
  lang,
  providersObject,
}) {
  return (
    <Row>
      <TableHeader rel="noopener noreferrer nofollow" target="_blank" isDisabled={isDisabled}>
        <TeamLogo
          width={24}
          height={24}
          team_name={team}
          enet_pulse_id={Object.values(offers)[0].participant.id}
        />
        <TeamName>{team}</TeamName>
      </TableHeader>
      <OddsRow>
        {Object.entries(offers).map(([provider, item], index) => (
          <OddCell
            key={index}
            link={generateOddsTracker(providersObject[provider], tracker, item.coupon_key)}
            prettyLinkUrl={generateTracker(
              prettyLinkFormat,
              providersObject[provider],
              'main',
              prettyLink,
              lang,
            )}
            odds={item.odds}
            odds_old={item.odds_old}
            isDisabled={isDisabled || item.status.id !== 1}
            isHighlighted={item.isHighlighted}
            isFuturesOdds={item.type === 'winner-of-stage' || 'winner-of-tournament'}
          />
        ))}
      </OddsRow>
    </Row>
  );
}

FuturesRow.propTypes = {
  tracker: PropTypes.string,
  team: PropTypes.string,
  lang: PropTypes.string,
  offers: PropTypes.shape({}),
  prettyLinkFormat: PropTypes.string,
  providersObject: PropTypes.shape({}),
  providers: PropTypes.arrayOf(PropTypes.shape({})),
  isDisabled: PropTypes.bool,
};
