import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { getOddsAmerican } from 'sports-util';
import Arrow from '../arrow';
import Star from '../star';

import { Cell, Odd } from './styles';

export default function OddCell({
  odds = '-.-',
  odds_old = '',
  link = '',
  isDisabled = false,
  isHighlighted = false,
  oddsFormat = 'decimal',
  prettyLinkUrl = '',
  isFuturesOdds = false,
}) {
  const [isUp, setIsUp] = useState(false);
  const [isDown, setIsDown] = useState(false);

  const oddRef = useRef(null);
  useEffect(() => {
    if (Number(odds) > Number(odds_old)) {
      setIsUp(true);
      setIsDown(false);
    }
    if (Number(odds) < Number(odds_old)) {
      setIsDown(true);
      setIsUp(false);
    }
    if (Number(odds) === Number(odds_old)) {
      setIsUp(false);
      setIsDown(false);
    }
    // Added logic to fade-out fade-in odds on odds change
    oddRef.current.classList.remove('odds-fade-in');
    setTimeout(() => {
      oddRef.current.classList.add('odds-fade-in');
    }, 100);
  }, [odds, odds_old]);

  const onOddsClick = (e) => {
    e.preventDefault();
    window.open(prettyLinkUrl, '_blank');
  };

  const getOddsFractional = (decimal) => {
    let betAmount = 5;
    if (decimal > 5) {
      betAmount = 1;
    }
    let totalWin = (betAmount * decimal).toFixed(0);
    return `${totalWin - betAmount}/${betAmount}`;
  };

  const getOddValue = (decimal) => {
    switch (oddsFormat) {
      case 'american':
        return getOddsAmerican(decimal);
      case 'fractional':
        return getOddsFractional(decimal.toFixed(2));
      default:
        return decimal.toFixed(2);
    }
  };

  return (
    <Cell
      onClick={(e) => onOddsClick(e)}
      href={prettyLinkUrl}
      rel="noopener noreferrer nofollow"
      isHighlighted={isHighlighted && !isDisabled}
      isDisabled={isDisabled}
      isFuturesOdds={isFuturesOdds}
    >
      <Odd className="value" ref={oddRef} data-testid="odds-cell">
        {getOddValue(odds)}
      </Odd>
      {isHighlighted ? (
        <Star />
      ) : (
        <>
          {!isDisabled && isUp && (
            <span className="up">
              <Arrow color="green" direction="up" />
            </span>
          )}
          {!isDisabled && isDown && (
            <span className="down">
              <Arrow color="red" direction="down" />
            </span>
          )}
        </>
      )}
    </Cell>
  );
}

OddCell.propTypes = {
  odds: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  odds_old: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  link: PropTypes.string,
  isDisabled: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  oddsFormat: PropTypes.oneOf(['american', 'decimal']),
};
