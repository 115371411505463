export function shouldRetry(error) {
  switch (error.response?.status) {
    case 404:
    case 422:
      return false;
    case 400:
    case 401:
    case 403:
    case 500:
    case 502:
    case 503:
    case 504:
    default:
      return true;
  }
}
