import styled, { css } from 'styled-components';

export const Cell = styled.a`
  background: #f6f6fb;
  height: 40px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  color: #757777;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #dcdddd;
  border-radius: 4px;

  @media (min-width: 768px) {
    width: 108px;
  }

  &:hover {
    border: 1px solid #000000;
    color: #000000;
  }

  > span.up,
  > span.down {
    position: absolute;
    right: 8px;
    top: 15px;
    display: flex;
  }
  ${(props) =>
    props.isFuturesOdds &&
    css`
      margin-left: 8px;
      width: 80px;
      font-weight: bold;
      background: #ffffff;
      box-sizing: border-box;
      font-size: 14px;
      line-height: 15px;
      color: #021d38;
      z-index: 1;
      outline: 1.5px solid #17182f;
      border: none;

      @media (min-width: 768px) {
        width: 80px;
      }

      &:hover {
        background: #f3f5f8;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
      }

      &:focus {
        background: #ffe7d2;
        outline: 1px solid #ff7500;
      }
    `}
  ${(props) =>
    props.isHighlighted &&
    css`
      background: #ebf1ff;
      border: 1.5px solid #165af8;

      ${(props) =>
        props.isFuturesOdds &&
        css`
          background: #ecf5ff;
          outline-color: #165af8;
          border: none;

          &:hover {
            background: #ecf5ff;
          }

          &:focus {
            background: #ffe7d2;
            outline: 1px solid #ff7500;
          }
        `}
    `}
  ${(props) =>
    props.isDisabled &&
    css`
      color: #bdbdbd;
      cursor: not-allowed;
      pointer-events: none;
      border: 1px solid #dcdddd;
      background: #ececec;

      ${(props) =>
        props.isFuturesOdds &&
        css`
          color: #a4adb7;
          outline-color: #dadee1;
          border: none;
        `}
    `}
`;

export const Odd = styled.span`
  opacity: 0;
`;
