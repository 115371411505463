import PropTypes from 'prop-types';
import { LoadingBox, Wrapper, Err } from './styles';

export default function OddsEventLoading({ isError = false }) {
  return (
    <Wrapper>
      <LoadingBox width={'120px'} height={25} top={5} left={80} />
      <LoadingBox width={'85px'} height={40} top={35} left={0} />
      <LoadingBox width={'85px'} height={40} top={35} left={100} />
      <LoadingBox width={'85px'} height={40} top={35} left={200} />
      <LoadingBox width={'150px'} height={20} top={80} left={65} />
      {isError && <Err>Error loading odds feed</Err>}
    </Wrapper>
  );
}

OddsEventLoading.propTypes = {
  isError: PropTypes.bool,
};
