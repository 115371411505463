import PropTypes from 'prop-types';

import OddsToplistBts from './components/organisms/odds-toplist/bts';
import OddsToplistCs from './components/organisms/odds-toplist/cs';
import OddsToplistGs from './components/organisms/odds-toplist/gs';
import OddsToplistHc from './components/organisms/odds-toplist/hc';
import OddsToplist from './components/organisms/odds-toplist';
import FuturesTable from './components/organisms/futures-table';
import OddsEvents from './components/organisms/odds-events';
import useWidgetData from './hooks/useWidgetData';

import GlobalStyle from './styles/global-styles';
import { Container, ContainerEvent, FuturesTableContainer, GlobleContainer } from './styles';

export default function App({
  dataToken,
  dataOddsFormat,
  dataLang,
  dataPrettyLink,
  dataPrettyLinkFormat,
  dataEventId,
  dataSportName,
  dataProviders,
  dataTrackers,
  dataOddType,
  dataOuValue,
  dataSportsWidget,
  isHeader,
}) {
  const searchParams = new URLSearchParams(window.location.search);
  const token = dataToken ? dataToken : !dataSportsWidget ? searchParams.get('token') : null;
  const oddsFormat = dataOddsFormat || searchParams.get('oddsFormat') || null;
  const lang = dataLang || searchParams.get('lang') || 'en';
  const langAPI = dataLang || searchParams.get('lang');
  const prettyLink = dataPrettyLink || searchParams.get('prettyLink') || null;
  const prettyLinkFormat = dataPrettyLinkFormat || searchParams.get('prettyLinkFormat') || null;
  let { data, error, isLoading, isError } = useWidgetData(token, langAPI);

  if (!token && !dataEventId) {
    return <h3>Missing data!</h3>;
  } else if (dataEventId && !token) {
    isLoading = false;

    data = {
      result: {
        event_ids: [dataEventId],
        sport_name: dataSportName,
        providers: dataProviders,
        tracker: dataTrackers,
        odds_types: dataOddType,
        ou_value: dataOuValue,
        widget_type: 'Event',
      },
    };
  }

  function getToplist(odds_types, props) {
    switch (odds_types) {
      case 'cs':
        return (
          <OddsToplistCs
            {...props}
            prettyLink="goto"
            prettyLinkFormat="mbo_cta_prefix_link,operator_name,tracker_type"
            oddType="cs"
          />
        );
      case 'bts':
        return (
          <OddsToplistBts
            {...props}
            prettyLink="goto"
            prettyLinkFormat="mbo_cta_prefix_link,operator_name,tracker_type"
            oddType="bts"
          />
        );
      case 'gs':
        return (
          <OddsToplistGs
            {...props}
            prettyLink="goto"
            prettyLinkFormat="mbo_cta_prefix_link,operator_name,tracker_type"
            oddType="gs"
          />
        );
      case 'ht_ft':
        return (
          <OddsToplist
            {...props}
            prettyLink="goto"
            prettyLinkFormat="mbo_cta_prefix_link,operator_name,tracker_type"
          />
        );
      case '1x2_hc':
        return (
          <OddsToplistHc
            {...props}
            prettyLink="goto"
            prettyLinkFormat="mbo_cta_prefix_link,operator_name,tracker_type"
          />
        );
      default:
        return (
          <OddsToplist
            {...props}
            prettyLink="goto"
            prettyLinkFormat="mbo_cta_prefix_link,operator_name,tracker_type"
          />
        );
    }
  }

  function ModuleComponent() {
    let props = {
      sportName: data?.result?.sport_name,
      providers: data?.result?.providers,
      oddsFormat: oddsFormat,
      lang: lang,
      prettyLink: prettyLink,
      prettyLinkFormat: prettyLinkFormat,
      tracker: data?.result?.tracker,
      oddType: data?.result?.odds_types,
      ouValue: data?.result?.ou_value,
      isHeader: isHeader,
    };
    switch (data?.result?.widget_type) {
      case 'Event':
        return (
          <GlobleContainer
            style={{ maxWidth: '1220px', width: '100%', overflow: 'hidden', display: 'grid' }}
            isSportsWidget={dataSportsWidget || searchParams.get('isSportsWidget')}
          >
            {data?.result?.event_ids.map((item, index) => {
              props = {
                ...props,
                key: index,
                eventId: item,
              };

              return dataSportsWidget || searchParams.get('isSportsWidget') ? (
                <ContainerEvent key={props.key}>
                  <OddsEvents {...props} />
                </ContainerEvent>
              ) : (
                <Container key={props.key}>{getToplist(data?.result?.odds_types, props)}</Container>
              );
            })}
          </GlobleContainer>
        );
      case 'Tournament':
        props = {
          ...props,
          tournamentId: data?.result?.tournament_id,
        };
        return (
          <FuturesTableContainer>
            <FuturesTable {...props} />
          </FuturesTableContainer>
        );
      default:
        return null;
    }
  }

  return (
    <>
      <GlobalStyle />

      {!isLoading && data?.result && data?.result?.providers.length > 0 && <ModuleComponent />}

      {!isLoading && isError && (
        <h3>{error.response?.data?.errors?.join(', ') || error.message}</h3>
      )}
    </>
  );
}

App.propTypes = {
  dataToken: PropTypes.string,
  dataOddsFormat: PropTypes.oneOf(['american', 'decimal']),
  dataLang: PropTypes.string,
  prettyLink: PropTypes.string,
  prettyLinkFormat: PropTypes.string,
  dataEventId: PropTypes.number,
  dataSportName: PropTypes.string,
  dataProviders: PropTypes.array,
  dataTrackers: PropTypes.string,
  dataOddType: PropTypes.string,
  dataOuValue: PropTypes.string,
  dataSportsWidget: PropTypes.bool,
  isHeader: PropTypes.bool,
};
