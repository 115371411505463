import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  getProvidersQuery,
  providersArrayToObject,
  getOddsHighlights,
  getOddsHeader,
  generateTracker,
  getTranslations,
  padNumber,
} from 'sports-util';
import { oddsTranslations } from 'sports-util/dist/constants';
import Header from 'components/molecules/header';
import Footer from 'components/molecules/footer';
import OddsLoading from 'components/atoms/odds-loading';
import ProviderRow from 'components/molecules/provider-row';
import Arrow from 'components/atoms/arrow';
import ToplistHeader from 'components/atoms/toplist-header';
import useOddsData from 'hooks/useOddsData';

import { ToplistWrapper, Handicap, Timestamp, SeeMore, Wrapper } from './styles';

const getOddsOffersSorted = (offers, oddType) => {
  var oddsOffersSorted = [];
  var offerType = oddType.toUpperCase().split('');
  offerType.forEach(function (type) {
    var offer = offers.filter(function (offer) {
      return offer.offer_type === type;
    });
    offer.length > 0 && oddsOffersSorted.push(offer[0]);
  });
  return oddsOffersSorted;
};

export default function OddsToplist({
  eventId = 3560740,
  sportName = 'soccer',
  oddType = '1x2',
  mockData = { odds: [] },
  providers = [],
  oddsFormat = 'decimal',
  prettyLink,
  prettyLinkFormat,
  tracker,
  lang,
  ouValue = 2.5,
}) {
  const boxRef = useRef([]);
  const btnRef = useRef([]);
  const labelRef = useRef([]);
  const timestampRef = useRef(null);
  const originalPosition = useRef([]);
  const [sortBy, setSortBy] = useState('');
  const [sortCol, setSortCol] = useState('');
  const [hcData, setHcData] = useState({});
  const searchParams = new URLSearchParams(window.location.search);
  const site = searchParams.get('site');

  const { data, error, isLoading, isError } =
    mockData.odds.length > 0
      ? {
          data: mockData,
          error: { message: '' },
          isLoading: false,
          isError: false,
        }
      : // eslint-disable-next-line react-hooks/rules-of-hooks
        useOddsData(eventId, sportName, oddType, getProvidersQuery(providers), lang, ouValue);

  const handleClick = (index) => {
    boxRef.current[index].classList.toggle('open');
    btnRef.current[index].classList.toggle('open');
    if (
      labelRef.current[index].innerText ===
      getTranslations(lang, 'see_less_offers', oddsTranslations)
    ) {
      labelRef.current[index].innerText = getTranslations(
        lang,
        'see_more_offers',
        oddsTranslations,
      );
    } else {
      labelRef.current[index].innerText = getTranslations(
        lang,
        'see_less_offers',
        oddsTranslations,
      );
    }
  };

  useEffect(() => {
    const today = new Date();
    if (!isLoading && !isError && data?.odds?.length) {
      timestampRef.current.innerText = `${getTranslations(
        lang,
        'last_update',
        oddsTranslations,
      )} ${getTranslations(lang, 'today', oddsTranslations)}, ${getTranslations(
        lang,
        'at',
        oddsTranslations,
      )} ${padNumber(today.getHours())}:${padNumber(today.getMinutes())}`;

      const hc = {};
      data?.odds?.forEach(({ odds_provider, offers } = {}) => {
        offers?.forEach((offer = {}) => {
          !hc[offer.starts_with] && Object.assign(hc, { [offer.starts_with]: {} });
          !hc[offer.starts_with][odds_provider.short_code] &&
            Object.assign(hc[offer.starts_with], {
              [odds_provider.short_code]: { odds_provider: odds_provider, offers: [] },
            });
          hc[offer.starts_with][odds_provider.short_code].offers.push(offer);
        });
      });
      setHcData(hc);
    }
  }, [data, lang, isLoading, isError]);

  if (isLoading)
    return (
      <Wrapper>
        <OddsLoading />
      </Wrapper>
    );
  if (isError)
    return (
      <Wrapper>
        <OddsLoading isError />
        {console.log(error.response?.data?.errors?.join(', ') || error.message)}
      </Wrapper>
    );
  if (!data?.odds?.length) return null;

  const hasStarted = data?.event?.status !== 'Not started';
  const providersObject = providersArrayToObject(providers);

  const handleSortBy = (sortType, oddType) => {
    if (sortType === sortBy) {
      setSortBy('');
    } else {
      setSortBy(sortType);
      oddType === 'ou' ? setSortCol('type') : setSortCol('offer_type');
    }
  };

  function sortOdds(array, sortBy) {
    let sortedOdds = array;

    if (originalPosition.current.length === 0) {
      originalPosition.current = array.map((item) => {
        return item.odds_provider.id;
      });
    }

    if (sortBy === '') {
      let sortedOriginalOdds = originalPosition.current.map((item) => {
        return sortedOdds.find((offer) => offer.odds_provider.id === item);
      });
      return sortedOriginalOdds;
    }

    sortedOdds = array.sort((a, b) => {
      return a.offers.find((offer) => offer[sortCol].toLowerCase() === sortBy).odds >
        b.offers.find((offer) => offer[sortCol].toLowerCase() === sortBy).odds
        ? -1
        : 1;
    });

    return sortedOdds;
  }

  oddType = '1x2';

  return (
    <Wrapper>
      <Header
        oddType={'1x2_hc'}
        title={`${data?.event?.event_participants[0]?.participant?.name} vs ${data?.event?.event_participants[1]?.participant?.name}`}
        date={data?.event?.start}
        lang={lang}
      />
      {Object.keys(hcData)
        .sort()
        .map((starts_with, index) => {
          console.log(index);
          console.log(starts_with);
          const odds = Object.values(hcData[starts_with]);
          const oddsSorted = sortOdds(getOddsHighlights(odds, oddType), sortBy).filter(
            (item) => item,
          );
          return (
            <>
              <ToplistWrapper
                ref={(el) => (boxRef.current[index] = el)}
                className="toplist-wrapper"
              >
                <Handicap>{`Starts ${starts_with.split('').join(' ')}`}</Handicap>
                <ToplistHeader
                  oddType={oddType}
                  sortBy={sortBy}
                  splitBy={getOddsHeader(oddType, Object.values(hcData[starts_with]), lang)}
                  handleSortBy={handleSortBy}
                />
                {oddsSorted.map((item) => {
                  const isFutureOdds =
                    item?.offers[0] &&
                    (item?.offers[0]?.type === 'winner-of-stage' ||
                      item?.offers[0]?.type === 'winner-of-tournament');
                  return (
                    <ProviderRow
                      key={item.odds_provider.id}
                      oddsData={getOddsOffersSorted(item.offers, oddType)}
                      prettyLinkUrl={
                        site &&
                        `https://${site}.gigmedia.com${generateTracker(
                          prettyLinkFormat,
                          providersObject[item?.odds_provider?.short_code],
                          tracker,
                          prettyLink,
                          lang,
                        )}`
                      }
                      tracker={tracker}
                      oddsFormat={oddsFormat}
                      provider={providersObject[item?.odds_provider?.short_code]}
                      isDisabled={hasStarted}
                      width={isFutureOdds ? 44 : 40}
                      height={isFutureOdds ? 44 : 40}
                    />
                  );
                })}
              </ToplistWrapper>
              {oddsSorted.length > 3 && (
                <SeeMore
                  type="button"
                  className="see-more-btn"
                  ref={(el) => (btnRef.current[index] = el)}
                  onClick={() => handleClick(index)}
                >
                  <span ref={(el) => (labelRef.current[index] = el)}>
                    {getTranslations(lang, 'see_more_offers', oddsTranslations)}
                  </span>
                  <Arrow color="blue" direction="down" />
                </SeeMore>
              )}
            </>
          );
        })}

      <Timestamp hasStarted={hasStarted} ref={timestampRef}>
        Last Updated: Today, at 00:00
      </Timestamp>
      {hasStarted && <Footer lang={lang} />}
    </Wrapper>
  );
}

OddsToplist.propTypes = {
  eventId: PropTypes.number,
  sportName: PropTypes.string,
  oddType: PropTypes.string,
  oddsFormat: PropTypes.oneOf(['american', 'decimal']),
  mockData: PropTypes.shape({}),
  providers: PropTypes.array,
  lang: PropTypes.string,
  prettyLink: PropTypes.string,
  prettyLinkFormat: PropTypes.string,
  tracker: PropTypes.string,
};
