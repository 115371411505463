import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  getProvidersQuery,
  providersArrayToObject,
  getOddsHighlights,
  getOddsHeader,
  getOddsOffersSorted,
  generateTracker,
  getTranslations,
  padNumber,
} from 'sports-util';
import { oddsTranslations } from 'sports-util/dist/constants';
import Header from 'components/molecules/header';
import Footer from 'components/molecules/footer';
import OddsLoading from 'components/atoms/odds-loading';
import ProviderRow from 'components/molecules/provider-row';
import Arrow from 'components/atoms/arrow';
import ToplistHeader from 'components/atoms/toplist-header';
import useOddsData from 'hooks/useOddsData';

import { ToplistWrapper, Timestamp, SeeMore, Wrapper } from './styles';

export default function OddsToplist({
  eventId = 3560740,
  sportName = 'soccer',
  oddType = '1x2',
  mockData = { odds: [] },
  providers = [],
  oddsFormat = 'decimal',
  prettyLink,
  prettyLinkFormat,
  tracker,
  lang,
  ouValue = 2.5,
}) {
  const boxRef = useRef(null);
  const btnRef = useRef(null);
  const labelRef = useRef(null);
  const timestampRef = useRef(null);
  const originalPosition = useRef([]);
  const [sortBy, setSortBy] = useState('');
  const [sortCol, setSortCol] = useState('');
  const searchParams = new URLSearchParams(window.location.search);
  const site = searchParams.get('site');

  const { data, error, isLoading, isError } =
    mockData.odds.length > 0
      ? {
          data: mockData,
          error: { message: '' },
          isLoading: false,
          isError: false,
        }
      : // eslint-disable-next-line react-hooks/rules-of-hooks
        useOddsData(eventId, sportName, oddType, getProvidersQuery(providers), lang, ouValue);

  const handleClick = () => {
    boxRef.current.classList.toggle('open');
    btnRef.current.classList.toggle('open');
    if (labelRef.current.innerText === getTranslations(lang, 'see_less_offers', oddsTranslations)) {
      labelRef.current.innerText = getTranslations(lang, 'see_more_offers', oddsTranslations);
    } else {
      labelRef.current.innerText = getTranslations(lang, 'see_less_offers', oddsTranslations);
    }
  };

  useEffect(() => {
    const today = new Date();
    !isLoading &&
      !isError &&
      data?.odds?.length &&
      (timestampRef.current.innerText = `${getTranslations(
        lang,
        'last_update',
        oddsTranslations,
      )} ${getTranslations(lang, 'today', oddsTranslations)}, ${getTranslations(
        lang,
        'at',
        oddsTranslations,
      )} ${padNumber(today.getHours())}:${padNumber(today.getMinutes())}`);
  }, [data, lang, isLoading, isError]);

  if (isLoading)
    return (
      <Wrapper>
        <OddsLoading />
      </Wrapper>
    );
  if (isError)
    return (
      <Wrapper>
        <OddsLoading isError />
        {console.log(error.response?.data?.errors?.join(', ') || error.message)}
      </Wrapper>
    );
  if (!data?.odds?.length) return null;

  const hasStarted = data?.event?.status !== 'Not started';
  const providersObject = providersArrayToObject(providers);

  const highlightedOdds = getOddsHighlights(data.odds, oddType === 'ht_ft' ? '1x2' : oddType);

  const handleSortBy = (sortType, oddType) => {
    if (sortType === sortBy) {
      setSortBy('');
    } else {
      setSortBy(sortType);
      oddType === 'ou' ? setSortCol('type') : setSortCol('offer_type');
    }
  };

  function sortOdds(array, sortBy) {
    let sortedOdds = array;

    if (originalPosition.current.length === 0) {
      originalPosition.current = array.map((item) => {
        return item.odds_provider.id;
      });
    }

    if (sortBy === '') {
      let sortedOriginalOdds = originalPosition.current.map((item) => {
        return sortedOdds.find((offer) => offer.odds_provider.id === item);
      });
      return sortedOriginalOdds;
    }

    sortedOdds = array.sort((a, b) => {
      return a.offers.find((offer) => offer[sortCol].toLowerCase() === sortBy).odds >
        b.offers.find((offer) => offer[sortCol].toLowerCase() === sortBy).odds
        ? -1
        : 1;
    });

    return sortedOdds;
  }

  return (
    <Wrapper>
      <Header
        oddType={oddType}
        title={`${data?.event?.event_participants[0]?.participant?.name} vs ${data?.event?.event_participants[1]?.participant?.name}`}
        date={data?.event?.start}
        lang={lang}
      />
      <ToplistWrapper ref={boxRef} className="toplist-wrapper">
        <ToplistHeader
          oddType={oddType === 'ht_ft' ? '1x2' : oddType}
          sortBy={sortBy}
          splitBy={getOddsHeader(oddType === 'ht_ft' ? '1x2' : oddType, data?.odds, lang)}
          handleSortBy={handleSortBy}
        />
        {sortOdds(highlightedOdds, sortBy).map((item) => {
          const isFutureOdds =
            item?.offers[0] &&
            (item?.offers[0]?.type === 'winner-of-stage' ||
              item?.offers[0]?.type === 'winner-of-tournament');

          return (
            <ProviderRow
              key={item.odds_provider.id}
              oddsData={getOddsOffersSorted(item.offers, oddType === 'ht_ft' ? '1x2' : oddType)}
              prettyLinkUrl={
                site &&
                `http://${site}.gigmedia.com${generateTracker(
                  prettyLinkFormat,
                  providersObject[item?.odds_provider?.short_code],
                  tracker,
                  prettyLink,
                  lang,
                )}`
              }
              tracker={tracker}
              oddsFormat={oddsFormat}
              provider={providersObject[item?.odds_provider?.short_code]}
              isDisabled={hasStarted}
              width={isFutureOdds ? 44 : 40}
              height={isFutureOdds ? 44 : 40}
            />
          );
        })}
      </ToplistWrapper>

      <Timestamp hasStarted={hasStarted} ref={timestampRef}>
        Odds Updated: Today, at 00:00
      </Timestamp>
      {hasStarted && <Footer lang={lang} />}
      {data.odds.length > 3 && (
        <SeeMore type="button" className="see-more-btn" ref={btnRef} onClick={() => handleClick()}>
          <span ref={labelRef}>{getTranslations(lang, 'see_more_offers', oddsTranslations)}</span>
          <Arrow color="blue" direction="down" />
        </SeeMore>
      )}
    </Wrapper>
  );
}

OddsToplist.propTypes = {
  eventId: PropTypes.number,
  sportName: PropTypes.string,
  oddType: PropTypes.string,
  oddsFormat: PropTypes.oneOf(['american', 'decimal']),
  mockData: PropTypes.shape({}),
  providers: PropTypes.array,
  lang: PropTypes.string,
  prettyLink: PropTypes.string,
  prettyLinkFormat: PropTypes.string,
  tracker: PropTypes.string,
};
