import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getTranslations, padNumber } from 'sports-util';
import { oddsTranslations } from 'sports-util/dist/constants';

import { Wrapper, TopBox, TitleBox, DateBox, Timestamp } from './styles';

export default function Header({ oddType = '', title = '', date = '', lang = '' }) {
  const eventDate = new Date(date);
  const timeZoneate = new Date(
    Date.UTC(
      eventDate.getFullYear(),
      eventDate.getMonth(),
      eventDate.getDate(),
      eventDate.getHours(),
      eventDate.getMinutes(),
    ),
  );
  const timestampRef = useRef(null);

  useEffect(() => {
    const today = new Date();
    title.length &&
      timestampRef.current &&
      (timestampRef.current.innerText = `${getTranslations(
        lang,
        'last_update',
        oddsTranslations,
      )} ${getTranslations(lang, 'today', oddsTranslations)}, ${getTranslations(
        lang,
        'at',
        oddsTranslations,
      )} ${padNumber(today.getHours())}:${padNumber(today.getMinutes())}`);
  }, [title, lang, timestampRef]);

  const oddTypeTitle = (oddType) => {
    switch (oddType) {
      case '1x2':
        return '';
      case '1x2_hc':
        return '3 Way Handicap';
      case 'bts':
        return 'Both Teams to Score';
      case 'cs':
        return 'Correct Score';
      case 'ou':
        return 'Over/Under';
      case 'gs':
        return 'GoalScorer - Anytime';
      case 'ht_ft':
        return 'Half Time Winner';
      default:
        return '';
    }
  };

  return (
    <Wrapper>
      <TopBox>
        <TitleBox oddType={oddType}>
          <span>{title}</span>
          <span>{oddTypeTitle(oddType)}</span>
        </TitleBox>
        {oddType !== 'Winner table' ? (
          <DateBox>
            <span>Match Starts</span>
            <span>{`${padNumber(timeZoneate.getDate())}/${padNumber(
              timeZoneate.getMonth() + 1,
            )} - ${padNumber(timeZoneate.getHours())}:${padNumber(
              timeZoneate.getMinutes(),
            )}`}</span>
          </DateBox>
        ) : (
          <Timestamp ref={timestampRef}>Last Updated: Today, at 00:00</Timestamp>
        )}
      </TopBox>
    </Wrapper>
  );
}

Header.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  oddType: PropTypes.string,
};
