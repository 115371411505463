import PropTypes from 'prop-types';

import { getTranslations } from 'sports-util';
import { oddsTranslations } from 'sports-util/dist/constants';

import { Wrapper, TextInfo } from './styles';

export default function Footer({ link = '', lang }) {
  return (
    <Wrapper>
      <TextInfo>{getTranslations(lang, 'footer_text', oddsTranslations)}</TextInfo>
      {/* <Button href={link}>See more +</Button> */}
    </Wrapper>
  );
}

Footer.propTypes = {
  link: PropTypes.string,
  lang: PropTypes.string,
};
