import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  groupOddsByTeamAndProvider,
  groupOddsByProvider,
  providersArrayToObject,
  getOddsHighlights,
  generateTracker,
} from 'sports-util';
import Header from 'components/molecules/header';
import FuturesLoading from 'components/atoms/futures-loading';
import FuturesRow from 'components/molecules/futures-row';
import ProviderRow from 'components/molecules/provider-row';
import useFuturesData from 'hooks/useFuturesData';

import {
  Wrapper,
  FuturesTableWrapper,
  FuturesHeader,
  ProvidersRow,
  EmptyCell,
  FuturesOdds,
} from './styles';

export default function FuturesTable({
  tournamentId = 3560740,
  oddType = 'Winner table',
  mockData = { odds: [] },
  providers = [],
  lang,
  prettyLink,
  prettyLinkFormat,
  tracker,
}) {
  const [gameProviders, setGameProviders] = useState(null);
  const [winnerData, setWinnerData] = useState(null);

  const { data, error, isLoading, isError } =
    mockData.odds.length > 0
      ? {
          data: mockData,
          error: { message: '' },
          isLoading: false,
          isError: false,
        }
      : // eslint-disable-next-line react-hooks/rules-of-hooks
        useFuturesData(tournamentId);

  useEffect(() => {
    if (!isError && !isLoading && data?.odds?.length) {
      if (winnerData === null && gameProviders === null) {
        setWinnerData(groupOddsByTeamAndProvider(data.odds));

        setGameProviders(
          providers.filter(
            (e) => Object.keys(groupOddsByProvider(data.odds)).indexOf(e.short_name) !== -1,
          ),
        );
      }
    }
  }, [data, gameProviders, providers, winnerData, isError, isLoading]);

  if (isLoading || !data?.odds.length)
    return (
      <Wrapper>
        <FuturesLoading />
      </Wrapper>
    );
  if (isError)
    return (
      <Wrapper>
        <FuturesLoading isError />
        {console.log(error.response?.data?.errors?.join(', ') || error.message)}
      </Wrapper>
    );
  if (!data?.odds?.length) return null;

  const providersObject = providersArrayToObject(providers);
  const highlightedOdds = winnerData && getOddsHighlights(winnerData, oddType);

  return (
    <Wrapper>
      <Header oddType={oddType} title={data.event.name} lang={lang} />
      <FuturesTableWrapper>
        <FuturesHeader>
          <EmptyCell></EmptyCell>
          <ProvidersRow data-testid="providers-header">
            {gameProviders &&
              Object.values(gameProviders).map((provider, index) => (
                <ProviderRow
                  key={index}
                  prettyLinkUrl={generateTracker(
                    prettyLinkFormat,
                    providersObject[provider.short_name],
                    'main',
                    prettyLink,
                    lang,
                  )}
                  tracker={tracker}
                  provider={providersObject[provider.short_name]}
                  isDisabled={data?.event?.status !== 'Not started'}
                  oddType={oddType}
                  width={40}
                  height={40}
                />
              ))}
          </ProvidersRow>
        </FuturesHeader>
        <FuturesOdds data-testid="futures-odds">
          {highlightedOdds &&
            Object.entries(highlightedOdds).map(([team, values]) => (
              <FuturesRow
                key={team}
                team={team}
                offers={values}
                tracker={tracker}
                providers={gameProviders}
                providersObject={providersObject}
                prettyLinkFormat={prettyLinkFormat}
                isDisabled={Object.values(values)[0].status.id !== 1}
              />
            ))}
        </FuturesOdds>
      </FuturesTableWrapper>
    </Wrapper>
  );
}

FuturesTable.propTypes = {
  tournamentId: PropTypes.number,
  mockData: PropTypes.shape({}),
};
