import styled, { css } from 'styled-components';

export const ToplistWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 8px 0 8px 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
  padding: 0 16px;
  overflow: auto;
  height: 370px;
  &.open {
    max-height: 2000px;
    transition: max-height 0.5s ease-in;
  }

  > div:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  ${(props) =>
    props.showShadow &&
    css`
      -webkit-box-shadow: 0 4px 6px -6px #222;
      -moz-box-shadow: 0 4px 6px -6px #222;
      box-shadow: 0 4px 6px -6px #222;
    `}
`;

export const Timestamp = styled.div`
  width: 100%;
  font-size: 12px;
  line-height: 18px;
  color: #6e6e84;
  margin: 10px 0 13px;
  text-align: center;
  @media (min-width: 768px) {
    ${(props) => (props.hasStarted ? 'margin: 8px 0 8px;' : 'margin: 8px 0 16px;')}
  }
`;

export const SeeMore = styled.button`
  width: 100%;
  background: #f6f6fb;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  &:focus {
    border: none;
    outline: none;
  }
  span {
    margin-right: 7px;
    font-size: 16px;
    line-height: 24px;
    color: #165af8;
    font-weight: 500;
  }
  &.open {
    span:last-child {
      transform: rotate(0deg);
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  background: white;
  border: 1.5px solid #e3e6ef;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const PlayerWrapper = styled.div`
  width: 100%;
  border: 1px solid #e3e6ef;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem 1.6rem;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  > span:first-child {
    > span {
      margin-bottom: 2px;
      margin-right: 0.5rem;
    }
  }

  ${(props) =>
    props.hide &&
    css`
      display: none;
    `}

  &.show {
    display: flex;
  }
`;

export const PlayerOdd = styled.a`
  background: #f6f6fb;
  border: 1px solid #17182f;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #17182f;
  padding: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 6rem;
  cursor: pointer;

  img {
    border-radius: 6px;
  }

  ${(props) =>
    props.isHighlighted &&
    css`
      background: #ebf1ff;
      border: 1.5px solid #165af8;
    `}
`;

export const AllOdds = styled.div`
  display: none;

  &.open {
    display: block;
  }

  > div:first-child {
    padding: 0.6rem 1.6rem;
    background: #f6f6fb;
    border: 1px solid #e3e6ef;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #6e6e84;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const AllOddsProvider = styled.div`
  border: 1px solid #e3e6ef;
  display: grid;
  grid-template-columns: 1fr 0.1fr;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.7rem 1.6rem;
  gap: 1rem;
`;

export const Tandc = styled.span`
  grid-row: 2;
  grid-column: 1/3;
`;

export const LoadMoreButton = styled.button`
  width: auto;
  margin: 0 auto;
  background: #f6f6fb;
  border: 1px solid #17182f;
  padding: 0.6rem;
  border-radius: 0.4rem;
`;

export const AllOddsProviderInfo = styled.div`
  display: flex;
  flex-direction: column;

  > span:first-child {
    color: #165af8;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }

  > span:nth-child(2) {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #17182f;
  }
`;
