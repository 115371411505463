import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  padding: 16px 16px 0 16px;
`;

export const TopBox = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const TitleBox = styled.div`
  line-break: auto;
  span {
    display: block;
    line-height: 32px;
    font-size: 18px;
    font-weight: 600;
    color: #17182f;
    margin: 0 8px 0 0;

    @media (min-width: 768px) {
      margin: 0 16px 0 0;
    }
  }

  span:last-child {
    color: #17182f;
    font-size: 14px;
    font-weight: 400;
  }

  ${(props) =>
    props.oddType === 'Winner table' &&
    css`
      span {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #021d38;
      }
    `}
`;

export const DateBox = styled.div`
  display: flex;
  color: #222526;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 88px;
  height: 50px;
  padding: 4px 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  @media (max-width: 350px) {
    padding: 4px 0;
  }
`;

export const SelectBox = styled.div`
  margin-top: 8px;
  margin-bottom: 19px;
`;

export const Timestamp = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: #50657a;
  text-align: center;
`;
