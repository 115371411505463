import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import { shouldRetry } from '../services/error';
import { getAccessToken } from '../services';

export default function useAccessToken(maxRetries = 15, retryDelay = 2000) {
  return useQuery('access_token', () => getAccessToken(), {
    refetchOnWindowFocus: false,
    refetchInterval: false,
    retryDelay,
    retry: (retries, error) => retries < maxRetries && shouldRetry(error),
  });
}

useAccessToken.propTypes = {
  maxRetries: PropTypes.number,
  retryDelay: PropTypes.number,
};
