import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  min-height: 500px;
  width: ${(props) => props.width || '100%'};

  @media (max-width: 960px) {
    width: 100%;
  }
`;
export const Err = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 16px;
  left: 0;
  top: 50%;
  font-weight: 500;
`;
export const LoadingBox = styled.div`
  position: absolute;
  left: ${(props) => props.left}px;
  right: ${(props) => props.right}px;
  top: ${(props) => props.top}px;
  width: ${(props) => props.width};
  height: ${(props) => props.height}px;
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  animation: oodsLoading 1.4s ease infinite;
  background-size: 400% 100%;
  border-radius: 8px;
`;

export const Table = styled.div`
  width: calc(100vw - 48px);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-x: auto;
  padding-top: 52px;
  min-height: 200px;
  height: 100%;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const FuturesRow = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

export const FuturesLoadingBox = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height}px;
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  animation: oodsLoading 1.4s ease infinite;
  background-size: 400% 100%;
  border-radius: 8px;
`;

export const FuturesLoadingCell = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height}px;
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  animation: oodsLoading 1.4s ease infinite;
  background-size: 400% 100%;
  margin-left: 8px;
  border-radius: 8px;
`;

export const EmptyFuturesCell = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height}px;
  background: transparent;
  animation: oodsLoading 1.4s ease infinite;
  background-size: 400% 100%;
`;
