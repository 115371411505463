import styled, { css } from 'styled-components';

export const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  margin-bottom: 4px;

  @media (min-width: 768px) {
    padding-left: 135px;
  }

  flex-shrink: 0;
`;

export const OddTitle = styled.button`
  margin-left: 4px;
  flex: 1;
  text-align: center;
  font-size: 14px;
  color: #17182f;
  font-weight: 400;
  height: 25px;
  border: none;
  background: transparent;
  cursor: pointer;
  ${(props) =>
    props.isSorted &&
    css`
      position: relative;

      > span.down {
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
      }
    `}
`;

export const OddsRow = styled.div`
  display: flex;
  width: calc(100% - 44px);
`;
