import { useEffect } from 'react';

import useAccessToken from '../hooks/useAccessToken';

export const access = {
  token: '',
};

export const AuthProvider =
  process.env.NODE_ENV !== 'development'
    ? ({ children }) => children
    : ({ children }) => {
        const auth = useAccessToken();

        useEffect(() => {
          if (!access.token && auth?.data?.access_token) {
            access.token = auth.data.access_token;
            Object.freeze(access);
          }
        }, [auth]);

        return access.token || auth?.data?.access_token ? children : null;
      };
