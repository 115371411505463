import { useState } from 'react';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import { shouldRetry } from '../services/error';
import { getWinnerOfStage } from '../services';

export default function useFuturesData(
  tournamentId,
  onWindowFocus = false,
  refetchInterval = 60000,
  maxRetries = 20,
  retryDelay = 5000,
) {
  const [stop, setStop] = useState(false);
  return useQuery(['event', tournamentId], () => getWinnerOfStage(tournamentId), {
    onSuccess: (data) => !data?.length && setStop(true),
    refetchOnWindowFocus: onWindowFocus,
    refetchInterval: stop ? false : refetchInterval,
    retryDelay,
    retry: (retries, error) => retries < maxRetries && shouldRetry(error),
  });
}

useFuturesData.propTypes = {
  tournamentId: PropTypes.number,
  onWindowFocus: PropTypes.boolean,
  refetchInterval: PropTypes.number,
  maxRetries: PropTypes.number,
  retryDelay: PropTypes.number,
};
